<template>
  <div class="animated fadeIn">
    <!-- v-if="mode === $constants.FORM_MODE.CREATE" -->
    <b-row>
      <b-col>
        <b-button-group>
          <b-button
            variant="outline-dark"
            size="sm"
            :disabled="dataTable.isInserting"
            @click="addItem()"
            title="Add item"
          >
            <font-awesome-icon icon="plus" /> Add item
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(dataset)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @inline-update="onInlineUpdate"
      @inline-dropdown-change="onInlineDropdownChange"
      @column-update="onInlineColumnUpdate"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row)"
            title="Delete item"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import Vue from "vue";
import TableCustom from "@/components/TableCustom";

import { FORM_MODE } from "@/shared/constants";
import { mapState, mapActions } from "vuex";

export default {
  name: "AlcoholFinishingItemsTable",
  props: {
    afId: {
      type: [String, Number],
      default: ""
    },
    productId: {
      type: [String, Number],
      default: ""
    },
    afSourceId: {
      type: [String, Number],
      default: undefined
    },
    mode: {
      type: [Number],
      default: FORM_MODE.VIEW
    }
  },
  components: {
    TableCustom
  },
  data: function() {
    return {
      dataset: {
        name: "alcohol-finishing-items"
      },
      isLoading: false,
      selectAll: false,
      selectedRows: [],
      dropdowns: {
        distillations: [],
        alcoholFinishings: [],
        barrels: []
      },
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          showEmpty: true,
          filterByColumn: false,
          columns: ["Item Name", "Amount Used", "Actions"],
          editableColumns: ["Item Name", "Amount Used"],
          dropdownColumns: [{ name: "Item Name", options: [] }],
          perPage: 50,
          showCustomActions: true,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback,
          hideSettingsBar: true,
          readOnly: true
        }
      },

      dblclick: undefined,
      serverFilter: undefined
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    async initialize() {
      this.loadDictionaries();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.afId) {
        //this.dataTable.options.editableColumns = [];
        // this.dataTable.options.columns = ["Item Name", "Amount Used"];

        this.getData();
      }
    },

    onInlineDropdownChange(e) {
      let rowIndex = this.dataTable.dataSet.findIndex(
        i => i["ID"] === e.row["ID"]
      );
      let row = this.dataTable.dataSet[rowIndex];

      if (e.column === "Item Name") {
        row["item_id"] = e.value.id;

        if (
          this.afSourceId === this.$constants.ALC_FINISHING_SOURCE.DISTILLATIONS
        )
          row["distillation_number"] = e.value.distillation_number;

        row["amount_remaining"] = e.value.amount_remaining;

        /*
        this.$api
          .get(`inventory/item/${e.value.id}/positive-balance`)
          .then(response => {
            // self.dropdowns.lotNumber = response;

            self.dataTable.options.dropdownColumns.find(
              c => c.name === "Lot Number"
            ).options = response.map(u => ({
              id: u["HID"],
              label:
                "REM: " +
                u["Amount Remaining"] +
                " - " +
                u["Order Number"] +
                " - " +
                u["Order Date"] +
                " - " +
                u["Lot Number"]
            }));
          });
          */
      }

      Vue.set(this.dataTable.dataSet, rowIndex, row);
    },
    onInlineColumnUpdate() {},
    onInlineUpdate(e) {
      let row = this.dataTable.dataSet.find(i => i["ID"] === e.id);

      if (e.column === "Item Name") {
        row["item_id"] = e.value.id;
      }

      if (!e.value.label) row[e.column] = e.value;
      else row[e.column] = e.value.label;
    },
    async loadDistillations() {
      let self = this;

      let response = await this.$api.post("alcohol-finishing/distillations", {
        finished_product_id: this.productId
      });

      self.dropdowns.distillations = response;

      self.dataTable.options.dropdownColumns.find(
        c => c.name === "Item Name"
      ).options = response.map(u => ({
        id: u.distillation_storage_container_id,
        label: u["Item Name"],
        distillation_number: u.distillation_number,
        amount_remaining: u.storage_amount_remaining
      }));
    },
    async loadAlcFinishings() {
      let self = this;

      let response = await this.$api.post("alcohol-finishing/finishings", {
        finished_product_id: this.productId
      });

      self.dropdowns.alcohol_finishings = response;

      self.dataTable.options.dropdownColumns.find(
        c => c.name === "Item Name"
      ).options = response.map(u => ({
        id: u.finished_product_number,
        label: u["Item Name"],
        amount_remaining: u.remaining_amount
      }));
    },
    async loadBarrels() {
      let self = this;

      let response = await this.$api.post("alcohol-finishing/barrels", {
        finished_product_id: this.productId
      });

      self.dropdowns.barrels = response;

      self.dataTable.options.dropdownColumns.find(
        c => c.name === "Item Name"
      ).options = response.map(u => ({
        id: u.barrel_number,
        label: u["Item Name"],
        amount_remaining: u.amount_in_barrel_remaining
      }));
    },

    loadDictionaries() {},

    getDataSet() {
      return this.dataTable.dataSet;
    },
    addItem() {
      let newItem = {
        uid: this.$helpers.uuidv4(),

        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        item_id: 0,
        "Item Name": "",
        "Amount Used": ""
      };

      this.dataTable.dataSet = this.$refs.dataTable.insertNewRow(newItem);

      this.dataTable.isInserting = true;
    },

    async saveNewRecordCallback(newRecord) {
      let self = this;

      if (!newRecord["Item Name"]) {
        self.$form.makeToastError("Please select an item");
        return;
      }

      if (!newRecord["Amount Used"]) {
        self.$form.makeToastError("Please enter amount used");
        return;
      }

      if (+newRecord["Amount Used"] > +newRecord["amount_remaining"]) {
        self.$form.makeToastError(
          `Amount used must be less or equal to ${newRecord["amount_remaining"]}L`
        );
        return;
      } /*
      let data = {
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID
      };*/

      self.dataTable.isInserting = false;

      this.$emit("inserted", newRecord);

      return true;
    },

    async revertNewRecordCallback() {
      this.dataTable.isInserting = false;

      return true;
    },
    async deleteItem(row) {
      let confirm = await this.$form.showConfirmation(
        `Item #${row["Item Name"]} will be removed. Do you want to proceed?`
      );

      if (!confirm) return;

      let idProp =
        this.mode === this.$constants.FORM_MODE.CREATE ? "uid" : "ID";

      this.dataTable.dataSet = this.dataTable.dataSet.filter(
        i => i[idProp] !== row[idProp]
      );

      this.$refs.dataTable.drawTable(this.dataTable.dataSet);

      this.$emit("deleted", row);
    },
    async getData(payload) {
      this.dataTable.isLoading = true;

      let url = `alcohol-finishing/${this.afId}/source-items`;

      let response = await this.$api.get(url, payload);

      this.rawData = response;

      this.dataTable.isLoading = false;

      this.dataTable.dataSet = this.rawData;
    }
  },
  watch: {
    afSourceId: {
      immediate: true,
      handler(newVal) {
        //if (this.mode !== this.$constants.FORM_MODE.CREATE) return;

        if (+newVal === this.$constants.ALC_FINISHING_SOURCE.DISTILLATIONS)
          this.loadDistillations();

        if (+newVal === this.$constants.ALC_FINISHING_SOURCE.FINISHINGS)
          this.loadAlcFinishings();

        if (+newVal === this.$constants.ALC_FINISHING_SOURCE.BARRELS)
          this.loadBarrels();
      }
    },
    productId: {
      immediate: true,
      handler() {
        if (this.mode !== this.$constants.FORM_MODE.CREATE) return;

        if (
          this.afSourceId === this.$constants.ALC_FINISHING_SOURCE.DISTILLATIONS
        )
          this.loadDistillations();

        if (this.afSourceId === this.$constants.ALC_FINISHING_SOURCE.FINISHINGS)
          this.loadAlcFinishings();

        if (this.afSourceId === this.$constants.ALC_FINISHING_SOURCE.BARRELS)
          this.loadBarrels();
      }
    },

    "dataTable.dataSet"() {
      this.$emit("updated", { count: this.dataTable.dataSet.length });
    }
  }
};
</script>

<style scoped>
::v-deep .VueTables__table {
  overflow: visible !important;
}
::v-deep .table-responsive {
  overflow: visible !important;
}

::v-deep .ct-inline-select {
  width: 30em !important;
}
</style>
