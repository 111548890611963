<template>
  <div class="animated fadeIn">
    <b-card>
      <b-alert show variant="danger" v-if="reportingAlertText"
        >{{ reportingAlertText }}
      </b-alert>

      <b-alert show variant="danger" v-if="bottlingAlertText"
        >{{ bottlingAlertText }}
      </b-alert>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Overview" active>
          <!-- <form> -->
          <h5>
            Alcohol finishing details
            <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
          </h5>

          <hr />

          <b-row>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.finished_product.id"
                :value="data.finished_product.id"
                label="Finished Product ID"
                :readonly="true"
                :mode="mode"
                :required="controls.finished_product.required"
                @changed="updateField"
              />
            </b-col>

            <b-col lg="3" md="5" sm="5">
              <inline-select
                :id="controls.finished_product.id"
                :value="data.finished_product"
                :label="controls.finished_product.label"
                :readonly="controls.finished_product.readonly"
                :options="controls.finished_product.options"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
                :group_values="controls.finished_product.group_values"
                :group_label="controls.finished_product.group_label"
              />
            </b-col>
            <b-col cols="1" style="align-self:center">
              <button
                v-if="mode !== $constants.FORM_MODE.CREATE"
                class="btn btn-success btn-sm"
                @click="editFinishedProduct()"
              >
                <font-awesome-icon icon="pencil-alt" />
              </button>
            </b-col>
            <b-col lg="5" md="6" sm="12">
              <inline-select
                v-if="data.finished_product.id !== ''"
                :id="controls.container.id"
                :value="data.container"
                :label="controls.container.label"
                :readonly="controls.container.readonly"
                :options="controls.container.options"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
                :custom-option-template="true"
              >
                <div slot="singleLabel" slot-scope="props">
                  <span
                    v-if="props.slotScope.option && props.slotScope.option.id"
                  >
                    Container #{{ props.slotScope.option.id }} [Free
                    {{ props.slotScope.option.freeSpace }}L of
                    {{ props.slotScope.option.size }}L]</span
                  >
                </div>

                <div slot="option" slot-scope="props">
                  <b-container no-glutters style="">
                    <hr style="margin-top:0px;padding-top:0px;" />
                    <span>
                      Container #{{ props.slotScope.option.id }} [Free
                      {{ props.slotScope.option.freeSpace }}L of
                      {{ props.slotScope.option.size }}L]
                    </span>
                    <b-table-simple
                      small
                      bordered
                      responsive
                      style="width: 300px;margin-top: 1em;font-size: 0.7rem;"
                      v-if="
                        props.slotScope.option.items &&
                          props.slotScope.option.items.length
                      "
                    >
                      <b-tbody>
                        <b-tr
                          v-for="(item, index) in props.slotScope.option.items"
                          :key="`copt-${index}`"
                        >
                          <b-td>{{ item.name }}</b-td>
                          <b-td>{{ item.amount }}L</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-container>
                </div>
              </inline-select>
            </b-col>
            <b-col lg="3" md="6" sm="12">
              <inline-select
                v-if="data.finished_product.id !== ''"
                :id="controls.af_source.id"
                :value="data.af_source"
                :label="controls.af_source.label"
                :readonly="controls.af_source.readonly"
                :options="controls.af_source.options"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateAfSource"
                :required="true"
              />
            </b-col>
          </b-row>
          <!--</form>-->
          <b-row v-if="data.af_source">
            <b-col>
              <hr />
              <alcohol-finishing-items-table
                ref="af-items"
                :af-id="id"
                :product-id="data.finished_product.id"
                :af-source-id="data.af_source.id"
                :mode="mode"
                @updated="onItemsTableUpdate"
                @inserted="onItemsTableInsert"
                @deleted="onItemsTableDelete"
              ></alcohol-finishing-items-table>
            </b-col>
          </b-row>
          <hr />
          <!-- <form>-->
          <b-row>
            <b-col lg="3" md="6" sm="6">
              <inline-date-picker
                :id="controls.af_date.id"
                :value-single="data.af_date"
                :label="controls.af_date.label"
                :readonly="controls.af_date.readonly"
                :mode="mode"
                :required="controls.af_date.required"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.final_amount.id"
                :value="data.final_amount"
                :label="controls.final_amount.label"
                :readonly="controls.final_amount.readonly"
                :mode="mode"
                :required="controls.final_amount.required"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.container_alcohol_kg.id"
                :value="data.container_alcohol_kg"
                :label="controls.container_alcohol_kg.label"
                :readonly="controls.container_alcohol_kg.readonly"
                :mode="mode"
                :required="controls.container_alcohol_kg.required"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-select
                :id="controls.container_weight.id"
                :value="data.container_weight"
                :label="controls.container_weight.label"
                :readonly="controls.container_weight.readonly"
                :options="controls.container_weight.options"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.actual_alcohol_kg.id"
                :value="data.actual_alcohol_kg"
                :label="controls.actual_alcohol_kg.label"
                :readonly="controls.actual_alcohol_kg.readonly"
                :mode="mode"
                :required="controls.actual_alcohol_kg.required"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.actual_product_mas.id"
                :value="data.actual_product_mas"
                :label="controls.actual_product_mas.label"
                :readonly="controls.actual_product_mas.readonly"
                :mode="mode"
                :required="controls.actual_product_mas.required"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-select
                :id="controls.wanted_product_mas.id"
                :value="data.wanted_product_mas"
                :label="controls.wanted_product_mas.label"
                :readonly="controls.wanted_product_mas.readonly"
                :options="controls.wanted_product_mas.options"
                :allow-empty="false"
                :multiple="false"
                :taggable="true"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.wanted_product_percentage.id"
                :value="data.wanted_product_percentage"
                :label="controls.wanted_product_percentage.label"
                :readonly="controls.wanted_product_percentage.readonly"
                :mode="mode"
                :required="controls.wanted_product_percentage.required"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row> </b-row>

          <b-row>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.water_amount_kg.id"
                :value="data.water_amount_kg"
                :label="controls.water_amount_kg.label"
                :readonly="controls.water_amount_kg.readonly"
                :mode="mode"
                :required="controls.water_amount_kg.required"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.water_amount_l.id"
                :value="data.water_amount_l"
                :label="controls.water_amount_l.label"
                :readonly="controls.water_amount_l.readonly"
                :mode="mode"
                :required="controls.water_amount_l.required"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.alcohol_amount_l.id"
                :value="data.alcohol_amount_l"
                :label="controls.alcohol_amount_l.label"
                :readonly="controls.alcohol_amount_l.readonly"
                :mode="mode"
                :required="controls.alcohol_amount_l.required"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.amount_finished_total_kg.id"
                :value="data.amount_finished_total_kg"
                :label="controls.amount_finished_total_kg.label"
                :readonly="controls.amount_finished_total_kg.readonly"
                :mode="mode"
                :required="controls.amount_finished_total_kg.required"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.amount_finished_total_l.id"
                :value="data.amount_finished_total_l"
                :label="controls.amount_finished_total_l.label"
                :readonly="controls.amount_finished_total_l.readonly"
                :mode="mode"
                :required="controls.amount_finished_total_l.required"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.amount_finished_total_pg.id"
                :value="data.amount_finished_total_pg"
                :label="controls.amount_finished_total_pg.label"
                :readonly="controls.amount_finished_total_pg.readonly"
                :mode="mode"
                :required="controls.amount_finished_total_pg.required"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.amount_finished_total_wg.id"
                :value="data.amount_finished_total_wg"
                :label="controls.amount_finished_total_wg.label"
                :readonly="controls.amount_finished_total_wg.readonly"
                :mode="mode"
                :required="controls.amount_finished_total_wg.required"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="6" sm="6">
              <inline-select
                :id="controls.status.id"
                :value="data.status"
                :label="controls.status.label"
                :readonly="controls.status.readonly"
                :options="controls.status.options"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-select
                :id="controls.filtered.id"
                :value="data.filtered"
                :label="controls.filtered.label"
                :readonly="controls.filtered.readonly"
                :options="controls.filtered.options"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateSelect"
                :required="controls.filtered.required"
              />
            </b-col>

            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.remaining_amount.id"
                :value="data.remaining_amount"
                :label="controls.remaining_amount.label"
                :readonly="controls.remaining_amount.readonly"
                :mode="mode"
                :required="controls.remaining_amount.required"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <inline-input
                :id="controls.finishing_notes.id"
                :value="data.finishing_notes"
                :label="controls.finishing_notes.label"
                :readonly="false"
                :mode="mode"
                :rows="3"
                :required="false"
                :is-text-area="true"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.created_by.id"
                :value="data.created_by"
                :label="controls.created_by.label"
                :readonly="true"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.modified_by.id"
                :value="data.modified_by"
                :label="controls.modified_by.label"
                :readonly="true"
              />
            </b-col>

            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.created.id"
                :value="data.created"
                :label="controls.created.label"
                :readonly="true"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.modified.id"
                :value="data.modified"
                :label="controls.modified.label"
                :readonly="true"
              />
            </b-col>
          </b-row>
          <!-- </form>-->
          <hr />
          <form-submission-actions
            :mode="mode"
            :loading="{
              save: saveInProgress,
              saveAndView: saveAndViewInProgress
            }"
            :buttons-visibility="{
              previous: $customTable.getPrevKey(dataset, id),
              next: $customTable.getNextKey(dataset, id)
            }"
            @previous-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getPrevKey(dataset, id)
                }
              })
            "
            @next-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getNextKey(dataset, id)
                }
              })
            "
            @save="
              save('tabular').then(response =>
                response ? $router.push({ name: 'Alcohol finishings' }) : false
              )
            "
            @save-and-view="
              save('view').then(response =>
                response
                  ? $router.push({
                      name: 'Alcohol finishing submission',
                      params: { action: 'view', id: response }
                    })
                  : false
              )
            "
            @edit="
              $router.push({
                name: 'Alcohol finishing submission',
                params: { action: 'edit', id: id }
              })
            "
            @back="$router.push($store.getters['router/previousRoute'])"
          />
        </b-tab>
        <b-tab
          :title="controls.tabs.bottling.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <bottling-table
            ref="bottling-table"
            :alcohol-finishing-id="id"
            @loaded="onBottlingTableLoad"
          ></bottling-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";

import BottlingTable from "@/views/Production/Bottling/BottlingTable";
import AlcoholFinishingItemsTable from "./AlcoholFinishingItemsTable";

import { mapState, mapActions } from "vuex";

export default {
  name: "AlcoholFinishingSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    BottlingTable,
    AlcoholFinishingItemsTable
  },
  data: function() {
    return {
      dataset: {
        name: "alcohol-finishing"
      },
      reportingAlertText: undefined,
      bottlingAlertText: undefined,
      bottlingsData: { total: 0 },
      isBarrelsLoading: false,
      baseApiUrl: "alcohol-finishing",
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      af_sources: [],
      controls: {
        tabs: {
          overview: {
            title: "Overview"
          },
          bottling: {
            title: "Bottling"
          }
        },

        finished_product: {
          id: "select:finished_product",
          label: "Finished Product",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true,
          group_values: "group_values",
          group_label: "group_label",
          raw_options: []
        },
        edit_finished_product: {
          visible: true
        },
        container: {
          id: "select:container",
          label: "Used Container",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        af_source: {
          id: "select:af_source",
          label: "Alcohol finishing source",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },

        af_date: {
          id: "datepicker:af_date",
          label: "AF Date",
          changed: false,
          readonly: false,
          required: true
        },
        final_amount: {
          id: "input:final_amount",
          label: "Container weight (with alcohol)",
          changed: false,
          readonly: false,
          required: false
        },
        actual_product_mas: {
          id: "input:actual_product_mas",
          label: "Actual MAS",
          changed: false,
          readonly: false,
          required: true
        },
        container_alcohol_kg: {
          id: "input:container_alcohol_kg",
          label: "Alcohol Weight (kg)",
          changed: false,
          readonly: false,
          required: true
        },
        container_weight: {
          id: "select:container_weight",
          label: "Container Weight",
          options: [
            { id: "0.0", label: "No Container - 0kg" },
            { id: "4.4", label: "Small Can - 4.4kg" },
            { id: "6.6", label: "Large Can - 6.6kg" },
            { id: "15.4", label: "300l Tank - 15.4kg" }
          ],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        actual_alcohol_kg: {
          id: "input:actual_alcohol_kg",
          label: "Actual Alcohol Weight (kg)",
          changed: false,
          readonly: true,
          required: false
        },
        wanted_product_mas: {
          id: "select:wanted_product_mas",
          label: "Wanted MAS",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        wanted_product_percentage: {
          id: "input:wanted_product_percentage",
          label: "Wanted %",
          changed: false,
          readonly: true,
          required: false
        },
        water_amount_kg: {
          id: "input:water_amount_kg",
          label: "Water Amount Kg",
          changed: false,
          readonly: true,
          required: false
        },
        water_amount_l: {
          id: "input:water_amount_l",
          label: "Water Amount L",
          changed: false,
          readonly: true,
          required: false
        },
        alcohol_amount_l: {
          id: "input:alcohol_amount_l",
          label: "Alcohol Amount L",
          changed: false,
          readonly: true,
          required: false
        },
        amount_finished_total_kg: {
          id: "input:amount_finished_total_kg",
          label: "Amount Finished Total Kg",
          changed: false,
          readonly: true,
          required: false
        },
        amount_finished_total_l: {
          id: "input:amount_finished_total_l",
          label: "Amount Finished Total L",
          changed: false,
          readonly: true,
          required: false
        },
        amount_finished_total_pg: {
          id: "input:amount_finished_total_pg",
          label: "Amount Finished Total Pg",
          changed: false,
          readonly: true,
          required: false
        },
        amount_finished_total_wg: {
          id: "input:amount_finished_total_wg",
          label: "Amount Finished Total Wg",
          changed: false,
          readonly: true,
          required: false
        },
        status: {
          id: "select:status",
          label: "Status",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        remaining_amount: {
          id: "input:remaining_amount",
          label: "Remaining Amount",
          changed: false,
          readonly: true,
          required: false
        },
        filtered: {
          id: "select:filtered",
          label: "Filtered",
          options: [
            { id: "", label: "" },
            { id: "Yes", label: "Yes" }
          ],
          changed: false,
          readonly: false,
          required: false
        },
        finishing_notes: {
          id: "input:finishing_notes",
          label: "Finishing Notes",
          changed: false,
          readonly: false,
          required: true
        },

        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: true
        },
        modified: {
          id: "input:modified",
          label: "Modified",
          changed: false,
          readonly: true
        },
        created_by: {
          id: "input:created_by",
          label: "Created By",
          changed: false,
          readonly: true
        },
        modified_by: {
          id: "input:modified_by",
          label: "Modified By",
          changed: false,
          readonly: true
        }
      },
      data: {
        id: "",
        finished_product: {
          id: "",
          label: ""
        },
        container: {
          id: "",
          label: ""
        },
        af_source: undefined,
        af_date: "",
        actual_product_mas: "",
        container_alcohol_kg: "",
        container_weight: { id: "", name: "" },
        actual_alcohol_kg: "",
        wanted_product_mas: { id: "", name: "" },
        wanted_product_percentage: "",
        water_amount_kg: "",
        water_amount_l: "",
        alcohol_amount_l: "",
        amount_finished_total_kg: "",
        amount_finished_total_l: "",
        amount_finished_total_pg: "",
        amount_finished_total_wg: "",
        status: { id: "", name: "" },
        remaining_amount: "",
        filtered: { id: "", name: "" },
        finishing_notes: "",
        created_by: "",
        modified_by: "",
        created: "",
        modified: ""
      },
      selectAll: false,
      selectedRows: [],
      dropdowns: {},
      dblclick: undefined,
      serverFilter: undefined,
      rawContainersData: {
        containers: [],
        distItems: [],
        afItems: []
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  async mounted() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    async initialize() {
      if (this.action === "create")
        this.mode = this.$constants.FORM_MODE.CREATE;

      if (this.action === "edit") this.mode = this.$constants.FORM_MODE.EDIT;

      if (this.action === "view") this.mode = this.$constants.FORM_MODE.VIEW;

      if (this.mode !== this.$constants.FORM_MODE.CREATE) {
        this.data.id = this.id;

        this.controls.af_source.readonly = true;
        this.controls.finished_product.readonly = true;
      }

      await this.loadDictionaries();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.loadData(this.id);
      }

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.af_date = this.$nowClientAsString;
      }
    },
    async loadDictionaries() {
      let self = this;

      const finishedProducts = async () => {
        let response = await this.$api.get("dictionaries/finished-products");

        this.controls.finished_product.raw_options = response;

        let groups = [...new Set(response.map(i => i.classification))].sort();

        self.controls.finished_product.options = groups.map(g => ({
          group_label: `Category ${g}`,
          group_values: response
            .filter(i => i.classification === g)
            .map(u => ({
              id: u.id,
              label: u.name,
              barrel_product_ids: u.barrel_product_ids
            }))
        }));
        /*
        self.controls.finished_product.options = response.map(u => ({
          id: u.id,
          label: u.name,
          barrel_product_ids: u.barrel_product_ids
        }));
        */
      };

      const statuses = async () => {
        let response = await this.$api.get("dictionaries/af-statuses");

        self.controls.status.options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      const sources = async () => {
        let response = await this.$api.get("dictionaries/af-sources");

        self.controls.af_source.options = response.map(u => ({
          id: u.id,
          label: u.name
        }));

        self.af_sources = self.controls.af_source.options;
      };

      const containers = async () => {
        let response = await this.$api.get("dictionaries/storage-containers");

        self.rawContainersData.containers = response;
      };
      const activeContainerItemsDist = async () => {
        self.rawContainersData.distItems = await this.$api.get(
          "storage-container/dist-items-compact"
        );
      };

      const activeContainerItemsAF = async () => {
        self.rawContainersData.afItems = await this.$api.get(
          "storage-container/af-items-compact"
        );
      };

      const productMassMapping = async () => {
        let response = await this.$api.get(
          "alcohol-finishing/wanted-mas-mapping"
        );

        self.controls.wanted_product_mas.options = response.map(i => ({
          id: parseFloat(i.wanted_mas),
          label: parseFloat(i.wanted_mas),
          wanted_mas: parseFloat(i.wanted_mas),
          kg_liter_multiplier: parseFloat(i.kg_liter_multiplier),
          wanted_product_percentage: parseFloat(i.wanted_product_percentage)
        }));
      };

      await Promise.all([
        finishedProducts(),
        statuses(),
        sources(),
        containers(),
        activeContainerItemsDist(),
        activeContainerItemsAF(),
        productMassMapping()
      ]);

      this.updateContainerOptions();
    },
    itemsAmountMatch2AlcoholWeight() {
      let itemsAmount = this.getSourceItemsAmount();
      let tolerance = (itemsAmount * 5) / 100;

      if (
        this.data.container_alcohol_kg <= itemsAmount + tolerance &&
        this.data.container_alcohol_kg >= itemsAmount - tolerance
      )
        return true;

      return false;
    },
    getSourceItems() {
      return (this.data.items = this.$refs["af-items"]
        ? this.$refs["af-items"].getDataSet()
        : []);
    },
    edit() {},

    save(_mode) {
      this.data.items = this.getSourceItems();

      if (!this.data.items.length) {
        this.$form.makeToastError("You have to select source items!");
        return Promise.resolve(false);
      }
      if (!this.itemsAmountMatch2AlcoholWeight()) {
        this.$form.makeToastError(
          "Total amount of used items must fit to Alcohol Weight with 5% tolerance!"
        );
        //return Promise.resolve(false);
      }

      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let method = "";

      let url = this.baseApiUrl;

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = this.baseApiUrl;
      }

      if (
        this.mode === this.$constants.FORM_MODE.EDIT ||
        this.mode === this.$constants.FORM_MODE.VIEW
      ) {
        method = "put";
        url = `${this.baseApiUrl}/${this.data.id}`;
      }
      console.log("url, this.data:", url, this.data);
      this.data.af_source_id = this.data.af_source.id;

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(field, value) {
      this.data[field] = value;

      this.updateCalculations();

      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return;
      }
      console.log("updateField", field, value);
      if (this.mode === this.$constants.FORM_MODE.VIEW)
        this.save("view").then(() => this.loadData(this.id));
    },
    async updateSelect(id, value) {
      //this.$form.updateField(this.baseApiUrl, this, id, value, this.mode);

      this.data[id] = value;

      if (id === "container_weight" || id === "wanted_product_mas") {
        this.updateCalculations();
      }
      if (id === "container") {
        if (value.freeSpace === 0) {
          await this.$form.makeToastWarning(
            "Be aware! There is no free space in the selected container!"
          );
        }

        if (value.items.length > 0) {
          let sameProducts = true;

          for (let item of value.items) {
            if (item.id !== this.data.finished_product.id) {
              sameProducts = false;
              break;
            }
          }

          if (!sameProducts) {
            await this.$form.makeToastWarning(
              "Be aware! You have selected container with different products!"
            );
          }
        }
      }
      if (id === "finished_product") this.updateRelatedDropdowns(value.id);

      if (this.mode === this.$constants.FORM_MODE.VIEW)
        this.save("view").then(() => this.loadData(this.id));
    },
    async updateCalculations() {
      this.data.actual_alcohol_kg =
        this.data.container_alcohol_kg - this.data.container_weight.id;

      this.data.water_amount_kg =
        (this.data.actual_alcohol_kg *
          (this.data.actual_product_mas - this.data.wanted_product_mas.id)) /
        this.data.wanted_product_mas.id;

      this.data.water_amount_l = (this.data.water_amount_kg * 1000) / 998.2;

      this.data.amount_finished_total_kg =
        this.data.water_amount_kg + this.data.actual_alcohol_kg;

      let wp = this.controls.wanted_product_mas.options.find(
        i => i.wanted_mas == this.data.wanted_product_mas.id
      );

      if (wp) {
        this.data.amount_finished_total_l =
          this.data.amount_finished_total_kg * wp.kg_liter_multiplier;
        this.data.wanted_product_percentage = wp.wanted_product_percentage;
      }

      this.data.amount_finished_total_pg =
        (this.data.amount_finished_total_l *
          this.data.wanted_product_percentage) /
        100 /
        0.5 /
        3.7854118;

      this.data.amount_finished_total_wg =
        this.data.amount_finished_total_l / 3.7854118;

      this.data.alcohol_amount_l =
        (this.data.amount_finished_total_l *
          this.data.wanted_product_percentage) /
        100;

      // round values
      this.data.water_amount_kg = this.$helpers.round(
        this.data.water_amount_kg,
        2
      );
      this.data.water_amount_l = this.$helpers.round(
        this.data.water_amount_l,
        2
      );
      this.data.amount_finished_total_kg = this.$helpers.round(
        this.data.amount_finished_total_kg,
        2
      );
      this.data.amount_finished_total_l = this.$helpers.round(
        this.data.amount_finished_total_l,
        2
      );
      this.data.amount_finished_total_pg = this.$helpers.round(
        this.data.amount_finished_total_pg,
        2
      );
      this.data.amount_finished_total_wg = this.$helpers.round(
        this.data.amount_finished_total_wg,
        2
      );
      this.data.alcohol_amount_l = this.$helpers.round(
        this.data.alcohol_amount_l,
        2
      );

      if (this.data.amount_finished_total_l > this.data.container.size) {
        await this.$form.makeToastWarning(
          "Be aware! There is no free space in the selected container!"
        );
      }
    },
    async loadData(id) {
      let self = this;

      this.isLoading = true;

      try {
        let response = await this.$api.get(`${this.baseApiUrl}/${id}`);
        console.log("loadData.response:", response);
        this.isLoading = false;

        if (self.$_.isEmpty(response)) return;

        this.data.id = response["ID"];

        this.data.finished_product = {
          id: response["Product ID"],
          label: response["Product Name"]
        };

        this.data.container = this.controls.container.options.find(
          i => i.id === response["Container ID"]
        );

        this.data.actual_product_mas = response["Actual Product Mas"];
        this.data.container_alcohol_kg = response["Container Alcohol kg"];

        this.data.container_weight = this.controls.container_weight.options.find(
          i => +i.id == +response["Container Weight"]
        );
        this.data.actual_alcohol_kg = response["Actual Alcohol kg"];
        /*
        this.data.wanted_product_mas = this.controls.wanted_product_mas.options.find(
          i => +i.id == +response["Wanted Product Mas"]
        );*/

        let wantedProductMas = this.controls.wanted_product_mas.options.find(
          i => +i.id == +response["Wanted Product Mas"]
        );

        if (!wantedProductMas) {
          wantedProductMas = {
            id: this.$helpers.uuidv4(),
            label: response["Wanted Product Mas"]
          };
          this.controls.wanted_product_mas.options.push(wantedProductMas);
        }

        this.data.wanted_product_mas = wantedProductMas;

        this.data.wanted_product_percentage = response["Wanted Product %"];
        this.data.water_amount_kg = response["Water Amount Kg"];
        this.data.water_amount_l = response["Water Amount L"];
        this.data.alcohol_amount_l = response["Alcohol Amount L"];
        this.data.amount_finished_total_kg =
          response["Amount Finished Total Kg"];
        this.data.amount_finished_total_l = response["Amount Finished Total L"];
        this.data.amount_finished_total_pg =
          response["Amount Finished Total Pg"];
        this.data.amount_finished_total_wg =
          response["Amount Finished Total Wg"];

        this.data.af_date = response["AF Date"];

        this.data.status = {
          id: response["Status ID"],
          label: response["Status"]
        };

        this.data.af_source = this.controls.af_source.options.find(
          i => i.id === response["af_source_id"]
        );

        this.data.filtered = {
          id: response["Filtered"],
          label: response["Filtered"]
        };

        this.data.remaining_amount = response["Remaining Amount"];

        this.data.finishing_notes = response["Finishing Notes"];
        this.data.created = response["Created"];
        this.data.modified = response["Modified"];

        this.data.created_by = response["Created By"];
        this.data.modified_by = response["Modified By"];

        //reporting alert
        let processYM = +moment(this.data.af_date).format("YYYYMM"),
          currentYM = +this.$nowClient.format("YYYYMM"),
          currentDay = +this.$nowClient.format("DD");

        this.reportingAlertText =
          currentYM > processYM && currentDay > 5
            ? `Current alcohol finishing already been reported!`
            : undefined;

        if (this.$refs["af-items"]) {
          this.loadDictionaries();
          this.$refs["af-items"].getData();
        }

        this.bottlingsData = await this.$api.get(
          `alcohol-finishing/${this.id}/used-bottlings`
        );
        if (this.bottlingsData.total > 0)
          this.bottlingAlertText =
            "Current alcohol finishing has related bottlings. Any property changes will trigger a cascading update and recalculation in related bottlings";
      } catch (error) {
        console.log(error);

        this.$form.makeToastError(error);
      } finally {
        this.isLoading = false;
      }
    },
    updateContainerOptions() {
      //filter containers
      let p = this.rawContainersData.containers.map(a => {
        return { ...a };
      });

      p.forEach(c => {
        let distItems = this.rawContainersData.distItems.filter(
          d => d.container_id === c.id
        );
        let afItems = this.rawContainersData.afItems.filter(
          d => d.container_id === c.id
        );

        c.items = distItems.map(d => ({
          id: d.item_id,
          name: d.item_name,
          amount: d.remaining_amount
        }));

        c.items = [
          ...c.items,
          ...afItems.map(a => ({
            id: a.item_id,
            name: a.item_name,
            amount: a.remaining_amount
          }))
        ];

        c.size = parseInt(c.size);

        c.totalAmount = c.items.length
          ? c.items.reduce((a, b) => {
              return a + parseInt(b.amount);
            }, 0)
          : 0;

        c.freeSpace = c.size - c.totalAmount < 0 ? 0 : c.size - c.totalAmount;
      });

      p.forEach(i => {
        i.label = `Container #${i.id} [Free ${i.freeSpace}L of ${i.size}L]`;
      });

      this.controls.container.options = p;
    },
    async updateRelatedDropdowns(productId) {
      console.log("updateRelatedDropdowns", productId);
      this.updateContainerOptions();

      //filters sources
      //20200411
      //this.data.af_source = null;
      this.controls.af_source.options = this.af_sources;

      //if exists barrel_product_ids then isBarrelProduct
      let fp = this.controls.finished_product.raw_options.find(
        p => p.id === productId
      );

      let isBarrelProduct = fp && fp.barrel_product_ids ? true : false;

      if (!isBarrelProduct) {
        this.controls.af_source.options = this.af_sources.filter(
          i => i.label !== "Barrels"
        );
      }
    },
    updateAfSource(id, value) {
      console.log("updateAfSource", value);
      this.data.af_source = value;
      console.log("updateAfSource.this.data", this.data);
    },
    onItemsTableUpdate(payload) {
      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.controls.af_source.readonly = payload.count > 0;
        this.controls.finished_product.readonly = payload.count > 0;
      } else {
        this.controls.af_source.readonly = true;
        //this.controls.finished_product.readonly = true;
      }
    },
    onItemsTableInsert() {
      this.updateAlcoholWeight();

      if (this.mode === this.$constants.FORM_MODE.VIEW)
        this.save("view").then(() => this.loadData(this.id));
    },
    onItemsTableDelete() {
      this.updateAlcoholWeight();

      if (this.mode === this.$constants.FORM_MODE.VIEW)
        this.save("view").then(() => this.loadData(this.id));
    },
    onBottlingTableLoad(count) {
      if (count > 0) this.controls.tabs.bottling.title = `Bottlings (${count})`;
    },
    updateAlcoholWeight() {
      //update value only in create or edit mode to avoid auto update related fields in db
      //if (this.mode === this.$constants.FORM_MODE.VIEW) return;

      let itemsAmount = this.getSourceItemsAmount();

      this.data.container_alcohol_kg = itemsAmount;
    },
    getSourceItemsAmount() {
      let items = this.getSourceItems();

      let itemsAmount = items.reduce((a, b) => {
        return a + +b["Amount Used"];
      }, 0);

      return itemsAmount;
    },
    async editFinishedProduct() {
      let resp = this.bottlingsData;

      let messages = [];
      let isSameMonth = moment().isSame(this.data.af_date, "month");
      if (!isSameMonth)
        messages.push(
          "Current alcohol finishing has been produced not in the current month and already been reported. Any changes are not recommended."
        );
      if (resp.total > 0) {
        if (resp.transferred == 0 && resp.picklist == 0 && resp.invoice == 0) {
          messages.push(
            `Current alcohol finishing has bottlings which will be deleted if finished product will be changed.`
          );
        }
        if (resp.transferred > 0 || resp.picklist > 0 || resp.invoice > 0) {
          messages.push(
            `Current alcohol finishing has bottlings with external relations [transfers: ${resp.transferred},picklists:${resp.picklist},invoices:${resp.invoice}] which will not be deleted if finished product will be changed.`
          );
        }

        let confirm = await this.$form.showConfirmation(
          `You are going to make finished product field editable. Please be aware if you are going to change it! ` +
            messages.join("") +
            ` Do you want to continue?`
        );

        if (!confirm) return;
      }
      this.controls.finished_product.readonly = false;
    }
  }
};
</script>

<style scoped>
.chevron-icon {
  cursor: pointer;
}
</style>
